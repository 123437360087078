<template>
  <e-modal
    :visible="visible"
    title="商品转菜品"
    :width="300"
    :loading="loading"
    @close="onClose"
    @ok="onSubmitFun"
  >
    <a-spin tip="Loading..." :spinning="loading">
      <a-form>
        <e-info
          :labelCol="10"
          :valueCol="12"
          title="商品名称"
          :value="goods.title"
        />
        <e-info
          :labelCol="10"
          :valueCol="12"
          title="商品价格"
          :value="goods.stockPrice"
        />
        <e-input-number
          :labelCol="10"
          :valueCol="12"
          title="出售价格"
          v-model:value="price"
        />
        <e-switch
          :labelCol="10"
          :valueCol="10"
          title="是否必选"
          v-model:value="isMust"
        />
        <e-select :labelCol="10"
          :valueCol="10"
          title="类型"
          v-model:value="type"
          :options="options"
           />
      </a-form>
    </a-spin>
  </e-modal>
</template>

<script>
import { defineComponent, ref } from 'vue'
import form from '@/mixins/form'
import { GoodsClass } from '@/apis/goods'

const api = new GoodsClass()
export default defineComponent({
  mixins: [form],
  emits: ['ok'],
  components: {},
  setup (props, { emit }) {
    const loading = ref(false)
    const visible = ref(false)
    const isMust = ref(false)

    const goods = ref({})
    const type = ref(1)
    const price = ref(0)

    const onClose = () => {
      isMust.value = false
      loading.value = false
      visible.value = false
    }

    return {
      visible,
      loading,
      goods,
      isMust,
      type,
      onClose,
      options: [{
        label: '菜品',
        value: 0
      }, {
        label: '台位费',
        value: 1
      }, {
        label: '前厅商品',
        value: 2
      }, {
        label: '损耗',
        value: 3
      }],
      price
    }
  },

  methods: {
    open (formData) {
      this.goods = formData

      this.price = formData.stockPrice
      this.visible = true
    },

    onSubmitFun () {
      this.loading = true
      api.toDish(this.goods.id, this.isMust, this.type, this.price).then(() => {
        this.$message.success('商品转菜品成功')
        this.onClose()
      })
    }
  }
})
</script>

<style lang="less" scoped></style>
