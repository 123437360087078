<template>
  <e-modal
    :visible="visible"
    :title="title"
    :width="300"
    :loading="loading"
    @close="onClose"
    @ok="onSubmitFun"
  >
    <a-spin tip="Loading..." :spinning="loading">
      <a-form>
        <e-info :labelCol="10" :valueCol="12" title="剩余库存" :value="goods.quantity" />
        <e-info :labelCol="10" :valueCol="12" title="单位" :value="goods.unit" />
        <e-input-number title="申请数量" v-model:value="count" />
      </a-form>
    </a-spin>
  </e-modal>
</template>

<script>
import { defineComponent, ref } from 'vue'
import form from '@/mixins/form'
import { GoodsOutClass } from '@/apis/goods'

const api = new GoodsOutClass()
export default defineComponent({
  mixins: [form],
  emits: ['ok'],
  components: {},
  setup (props, { emit }) {
    const loading = ref(false)
    const visible = ref(false)

    const title = ref('')
    const goods = ref({})
    const unit = ref('')
    const count = ref(1)

    const onClose = () => {
      count.value = 1
      loading.value = false
      visible.value = false
    }

    return {
      title,
      visible,
      loading,
      goods,
      count,
      unit,
      onClose
    }
  },

  methods: {
    echounit (unit) {
      const units = this.$store.state.app.unit
      const data = units.filter(e => e.value === unit)
      return data[0].label
    },
    open (formData) {
      this.goods = formData
      // this.unit = this.echounit(formData.unit)
      this.title = '添加“' + formData.title + '”出库申请'
      this.visible = true
    },

    onReset () {
      this.modelRef.title = ''
      this.modelRef.icon = ''
      this.modelRef.thumbnail = ''
      this.modelRef.banner = ''
    },

    onSubmitFun () {
      this.loading = true
      api.create({
        goodsId: this.goods.id,
        quantity: this.count
      }).then(() => {
        this.$message.success('出库申请成功')
        this.onClose()
      })
    }
  }
})
</script>

<style lang="less" scoped></style>
