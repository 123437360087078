<template>
  <e-modal
    :visible="visible"
    :title="title"
    :width="300"
    :loading="loading"
    @close="onClose"
    @ok="onSubmitFun"
  >
    <a-spin tip="Loading..." :spinning="loading">
      <a-form>
        <e-info :labelCol="7" :valueCol="16" title="剩余库存" :value="goods.quantity" />
        <e-info :labelCol="7" :valueCol="16" title="单位" :value="goods.unit" />
        <e-input-number :labelCol="7" :valueCol="16" title="采购数量" v-model:value="count" :min="0" />
        <e-textarea :labelCol="7" :valueCol="16" title="备注" v-model:value="remark" />
      </a-form>
    </a-spin>
  </e-modal>
</template>

<script>
import { defineComponent, ref } from 'vue'
import form from '@/mixins/form'
import { PlanItemClass } from '@/apis/plan'

const api = new PlanItemClass()
export default defineComponent({
  mixins: [form],
  emits: ['ok'],
  components: {},
  setup (props, { emit }) {
    const loading = ref(false)
    const visible = ref(false)

    const title = ref('')
    const goods = ref({})
    const unit = ref('')
    const remark = ref('')
    const count = ref(1)

    const onClose = () => {
      count.value = 1
      loading.value = false
      visible.value = false
    }

    return {
      title,
      visible,
      loading,
      goods,
      count,
      unit,
      onClose,
      remark
    }
  },

  methods: {
    echounit (unit) {
      const units = this.$store.state.app.unit
      const data = units.filter(e => e.value === unit)
      return data[0].label
    },
    open (formData) {
      this.goods = formData
      this.title = '添加“' + formData.title + '”采购'
      this.visible = true
    },

    onSubmitFun () {
      this.loading = true
      api.createOne({
        goodsId: this.goods.id,
        count: this.count,
        remark: this.remark
      }).then(() => {
        this.$message.success('添加采购清单成功')
        this.onClose()
      })
    }
  }
})
</script>

<style lang="less" scoped></style>
